import { useEffect, useState} from 'react'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {useStore, useAuthStore} from '../store/store'
import Test from './TestComponent'
import Breadcrumbs from './BreadcrumbsComponent'

function Home(props){

  const isLoggedIn = useAuthStore(state => state.isLoggedIn);
  const [content, setContent] = useState(null);
  useEffect(()=>{
    console.log(String(isLoggedIn));
    if (!isLoggedIn){

      setContent(
        <div>
            <h4>Welcome to BrainCellData!</h4>
          <p>You are <strong>not</strong> logged in. Please log in using your Broad email to access data.</p>
        </div>
      )
    }else{
      setContent(
        <div>
          {/* <h1>Welcome</h1> */}
            <h4>Welcome to BrainCellData!</h4>
          <p>
              Check out our open access paper in Nature: <a href="https://www.nature.com/articles/s41586-023-06818-7">The molecular cytoarchitecture of the adult mouse brain</a>!
              </p>
          <p>

            This site is managed by the <a
              href="https://macoskolab.com">Macosko lab</a> at the <a
                href="https://www.broadinstitute.org">Broad Institute</a>. This
                site is currently home to two large datasets. The first is an
            atlas of transcriptionally defined cell types of the mouse brain
            generated by the clustering of single-nucleus RNA-seq across 100
          brain regions. The second is a Slide-seq spatial transcriptomics
          atlas of one mouse brain hemispehere, generated together with <a
            href="https://www.insitubiology.org/">Fei Chen’s lab</a>.

          </p>
          <p>You can explore the data using the following links or tabs on navigation bar:</p>
          <ul>
            <li><a href="/genex">GeneExp</a> - Gene expression in the mouse brain with spatial context</li>  
            <li><a href="/singlecell">SingleCell</a> - Transcriptionally defined cell clusters/types in the mouse brain</li>  
            <li><a href="/cellspatial">CellSpatial</a> - Cell types in the mouse brain with spatial context</li> 
          </ul>
          
          <p> For more information, and to download the data, please see the <a href="https://docs.braincelldata.org">documentation</a> pages.</p>
          <p>
            Please post on the <a href="https://forum.braincelldata.org">BrainCellData forum</a> in case of any queries, comments, or feature requests.
          </p>
          <p>
            <strong>2 minute video overview:</strong> (for high resolution playback, set quality to 720p60 via the gear icon in the lower right corner of the video player)
          </p>
          <iframe style={{width:"80%", height:"520px", border:"1px solid black", borderRadius:"5px"}} src="https://www.youtube.com/embed/KmBavDkAw1Y?version=3&vq=hd720p" frameborder="1" allowfullscreen>
          </iframe>
        </div>

      )
    }
  },[isLoggedIn]);

  return(
    <>
      <Breadcrumbs/>
      {content}
    </>
  );
}

export default Home;
